import {Inject, Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
      private translate: TranslateService,
      @Inject(DOCUMENT) private document: Document
  ) { }

  getDefaultLanguage(){
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.document.documentElement.lang = language;
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
    this.document.documentElement.lang = setLang;
  }

}
